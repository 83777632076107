import { Backdrop } from "@mui/material";
import React, { useContext } from "react";
import { SettingContext } from "../context/settingContext";

const BackdropComponent = () => {
  const styles = `
  @keyframes typeAndDelete {
  0%,
  10% {
    width: 0;
  }
  45%,
  55% {
    width: 10.5em;
  } /* adjust width based on content */
  90%,
  100% {
    width: 0;
  }
}

.overlay {
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(74, 74, 74, 0.8);
    z-index: 99999;
    justify-content: center;
    align-items: center;
  }

.text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: typeAndDelete 2s steps(11) infinite,
    blinkCursor 0.5s step-end infinite alternate;
  margin-top: 1.5em;
}

.text .red {
  color: red;
  font-family: Papyrus, fantasy;
  font-size: 4em;
  font-weight: bold;
}

.text .black {
  color: black;
  font-family: "Segoe UI", sans-serif; /* Font family set to Segoe UI */
  font-size: 3em;
  font-weight: bold;
}
`;
  const context = useContext(SettingContext);
  const { backdropOpen } = context;
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <div className="overlay">
          <style>{styles}</style>
          <div className="text">
            <span className="red">i</span>
            <span className="black">REFER</span>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default BackdropComponent;
