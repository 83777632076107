import React, { useContext } from "react";
import Router from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingTabProvider from "./context/landingTabContext";
import BackdropComponent from "./components/BackdropComponent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SettingContext } from "./context/settingContext";
import { red } from "@mui/material/colors";

const App = () => {
  const context = useContext(SettingContext);
  const { darkMode } = context;

  const theme = createTheme({
    palette: {
      primary: {
        main: red[900],
      },

      mode: darkMode ? "dark" : "light",
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            "@keyframes circularShadow": {
              "0%": {
                boxShadow:
                  "0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px -2px 4px rgba(255, 255, 255, 0.4)",
              },
              "25%": {
                boxShadow:
                  "4px 4px 6px rgba(0, 0, 0, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.4)",
              },
              "50%": {
                boxShadow:
                  "4px 0px 6px rgba(0, 0, 0, 0.2), inset -2px 0px 4px rgba(255, 255, 255, 0.4)",
              },
              "75%": {
                boxShadow:
                  "-4px -4px 6px rgba(0, 0, 0, 0.2), inset 2px 2px 4px rgba(255, 255, 255, 0.4)",
              },
              "100%": {
                boxShadow:
                  "0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px -2px 4px rgba(255, 255, 255, 0.4)",
              },
            },
            "@keyframes bounce": {
              "0%, 100%": {
                transform: "translateY(0)",
              },
              "50%": {
                transform: "translateY(-5px)",
              },
            },
            "&:hover": {
              animation: "bounce 0.3s, circularShadow 2s linear infinite",
              boxShadow:
                "0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px -2px 4px rgba(255, 255, 255, 0.4)",
            },
          },
        },
      },
    },
  });

  return (
    <>
      <BackdropComponent />
      <ThemeProvider theme={theme}>
        <ToastContainer
          autoClose={2000}
          theme={darkMode ? "dark" : "light"}
          closeOnClick
        />
        <LandingTabProvider>
          <Router />
        </LandingTabProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
